<template>
  <v-menu
    bottom
    left
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="ml-2"
        min-width="0"
        text
        rounded
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>
    <v-list
      :tile="false"
      nav
    >
      <v-list-item
        link
        @click="signOut"
      >
        <v-list-item-title>
          Sign out
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { mapMutations, mapActions } from 'vuex'

  export default {
    name: 'AppBarUserMenu',

    methods: {
      ...mapMutations({
        setDrawer: 'app/SET_DRAWER',
        setUser: 'app/SET_USER',
      }),
      ...mapActions({
        resetState: 'RESET_STATE',
      }),
      signOut () {
        this.resetState()
        this.$router.push({ name: 'Login' })
      },
    },
  }
</script>
<style scoped>
</style>
