<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="white"
  >
    <app-bar-path-item />
    <v-spacer />
    <app-bar-help-center-button />
    <app-bar-user-menu />
  </v-app-bar>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import AppBarHelpCenterButton from './AppBarHelpCenterButton.vue'
  import AppBarPathItem from './AppBarPathItem.vue'
  import AppBarUserMenu from './AppBarUserMenu.vue'

  export default {
    name: 'AppBar',
    components: { AppBarPathItem, AppBarHelpCenterButton, AppBarUserMenu },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
    }),
    computed: {
      ...mapState('app', ['drawer']),
    },

    methods: {
      ...mapMutations({
        setDrawer: 'app/SET_DRAWER',
        setUser: 'app/SET_USER',
      }),
    },
  }
</script>
<style scoped>
</style>
