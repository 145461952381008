<template>
  <v-btn
    rounded
    depressed
    color="cloud"
    class=" text-h5"
    href="https://intercom.help/careconnectmobile-answers"
    small
  >
    <v-icon
      left
      light
      color="primaryTeal"
    >
      mdi-help
    </v-icon>
    <span class="grey--text">
      Help Center
    </span>
  </v-btn>
</template>

<script>
  export default {
    name: 'AppBarHelpCenterButton',
  }
</script>
<style scoped>
</style>
